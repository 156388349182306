import React from 'react';
export const NavBarLightDataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      '/imgs/logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      // {
      //   name: 'item0',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [{ children: 'About', name: 'text' }],
      //   },
      //   subItem: [
      //     {
      //       name: 'sub0',
      //       className: 'item-sub',
      //       children: {
      //         className: 'item-sub-item',
      //         children: [
      //           {
      //             name: 'image0',
      //             className: 'item-image',
      //             children:
      //               '/imgs/small-ant-logo.svg',
      //           },
      //           {
      //             name: 'title',
      //             className: 'item-title',
      //             children: 'unlimited light works Design',
      //           },
      //           {
      //             name: 'content',
      //             className: 'item-content',
      //             children: 'Detailed description of the product, text such as what is it',
      //           },
      //         ],
      //       },
      //     },
      //     {
      //       name: 'sub1',
      //       className: 'item-sub',
      //       children: {
      //         className: 'item-sub-item',
      //         children: [
      //           {
      //             name: 'image0',
      //             className: 'item-image',
      //             children:
      //               '/imgs/small-ant-logo.svg',
      //           },
      //           {
      //             name: 'title',
      //             className: 'item-title',
      //             children: 'unlimited light works Design',
      //           },
      //           {
      //             name: 'content',
      //             className: 'item-content',
      //             children: 'Enterprise UI Design',
      //           },
      //         ],
      //       },
      //     },
      //   ],
      // },
      // {
      //   name: 'item1',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [{ children: 'Imagine', name: 'text1' }],
      //   },
      // },
      // {
      //   name: 'item2',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [{ children: 'Believe', name: 'text2' }],
      //   },
      // },
      // {
      //   name: 'item3',
      //   className: 'header3-item',
      //   children: {
      //     href: '#',
      //     children: [{ children: 'SeeFood', name: 'text3', key:"item3" }],
      //   },
      // },
    ],
  },
  // mobileMenu: { className: 'header3-mobile-menu' },
};
// export const NavBarDarkDataSource = {
//   wrapper: { className: 'header0 home-page-wrapper' },
//   page: { className: 'home-page' },
//   logo: {
//     className: 'header0-logo',
//     children: '/imgs/logo-purple.svg',
//   },
//   Menu: {
//     className: 'header0-menu',
//     children: [
//       {
//         name: 'item0',
//         className: 'header0-item',
//         children: {
//           href: '#',
//           children: [{ children: 'unlimited light workstime 1', name: 'text' }],
//         },
//         subItem: [
//           {
//             name: 'sub0',
//             className: 'item-sub',
//             children: {
//               className: 'item-sub-item',
//               children: [
//                 {
//                   name: 'image0',
//                   className: 'item-image',
//                   children:
//                     '/imgs/small-ant-logo.svg',
//                 },
//                 {
//                   name: 'title',
//                   className: 'item-title',
//                   children: 'unlimited light works Design',
//                 },
//                 {
//                   name: 'content',
//                   className: 'item-content',
//                   children: 'Enterprise UI Design',
//                 },
//               ],
//             },
//           },
//           {
//             name: 'sub1',
//             className: 'item-sub',
//             children: {
//               className: 'item-sub-item',
//               children: [
//                 {
//                   name: 'image0',
//                   className: 'item-image',
//                   children:
//                     '/imgs/small-ant-logo.svg',
//                 },
//                 {
//                   name: 'title',
//                   className: 'item-title',
//                   children: 'unlimited light works Design',
//                 },
//                 {
//                   name: 'content',
//                   className: 'item-content',
//                   children: 'Enterprise UI Design',
//                 },
//               ],
//             },
//           },
//         ],
//       },
//       {
//         name: 'item1',
//         className: 'header0-item',
//         children: {
//           href: '#',
//           children: [{ children: 'unlimited light workstime 2', name: 'text' }],
//         },
//       },
//       {
//         name: 'item2',
//         className: 'header0-item',
//         children: {
//           href: '#',
//           children: [{ children: 'unlimited light workstime 3', name: 'text' }],
//         },
//       },
//       {
//         name: 'item3',
//         className: 'header0-item',
//         children: {
//           href: '#',
//           children: [{ children: 'unlimited light workstime 4', name: 'text' }],
//         },
//       },
//     ],
//   },
//   mobileMenu: { className: 'header0-mobile-menu' },
// };
export const BannerDataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: 'Unlimited Light Works', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: 'Simply a higher level of Watermark',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: 'Boutique Watermarkgraphy services, at your fingertips.',
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#Content3_0',
          className: 'banner5-button',
          type: 'primary',
          children: 'Imagine',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      '/imgs/glossy-emoji-with-social-media-network-symbols-mobile-phone-and-burger.png',
  },
};
// Illustration by <a href="https://icons8.com/illustrations/author/JTmm71Rqvb2T">Dani Grapevine</a> from <a href="https://icons8.com/illustrations">Ouch!</a>
// export const DetailsRightDataSource = {
//   wrapper: { className: 'home-page-wrapper content1-wrapper' },
//   OverPack: { className: 'home-page content1', playScale: 0.3 },
//   imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
//   img: {
//     children: '/imgs/park.png',
//   },
//   textWrapper: { className: 'content1-text', md: 14, xs: 24 },
//   title: { className: 'content1-title', children: 'Enterprise Resource Management' },
//   content: {
//     className: 'content1-content',
//     children:
//       'Centralized orchestration, elastic scaling, continuous release and deployment of cloud resources, high availability and disaster recovery. Centralized orchestration, elastic scaling, continuous release and deployment of cloud resources, high availability and disaster recovery. Centralized orchestration, elastic scaling, continuous release and deployment of cloud resources, high availability and disaster recovery.',
//   },
// };
// export const VideoDataSource = {
//   wrapper: { className: 'home-page-wrapper content4-wrapper' },
//   page: { className: 'home-page content4' },
//   OverPack: { playScale: 0.3, className: '' },
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [
//       {
//         name: 'title',
//         children: 'unlimited light works Cloud provides professional services',
//         className: 'title-h1',
//       },
//       {
//         name: 'content',
//         className: 'title-content content4-title-content',
//         children: 'Technological Imagination, Financial Creativity',
//       },
//     ],
//   },
//   video: {
//     className: 'content4-video',
//     children: {
//       video: '/imgs/ali-example-video.mp4',
//       image: '/imgs/ali-example-video.jpg',
//     },
//   },
// };
export const Details6DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'Watermarks First',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: 'and Foremost',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
                '/imgs/icons/download.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Pre-Applied' },
          content: {
            className: 'content3-content',
            children:
                'Watermarks perfectly affixed at the ideal locations. Skip the hassle of having to apply the watermarks yourself.'
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
                '/imgs/icons/settings.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Simple' },
          content: {
            className: 'content3-content',
            children:
                'Easy customization option (after meeting minimum cumulative payment).',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
                '/imgs/icons/colors.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'So many possibilities' },
          content: {
            className: 'content3-content',
            children:
                'Any color you can think of (after meeting minimum cumulative payment).',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
                '/imgs/icons/improve.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Worry Free' },
          content: {
            className: 'content3-content',
            children:
                'Option to opt into our complimentary watermark improvement plan. If a watermark is improved within the first year of your purchase, receive a upgrade completely free of charge.',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
            '/imgs/icons/browser.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Quality Assured' },
          content: {
            className: 'content3-content',
            children:
              'Backed by years of watermarkgraphy experience, our watermarkgrapher\'s watermarks are sure to never fall off or fade!',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
                '/imgs/icons/safe.svg',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Secure' },
          content: {
            className: 'content3-content',
            children:
                'Secure, and safe. Your card payment information is collected, and processed solely by Stripe.',
          },
        },
      },
    ],
  },
};
export const Pricing3DataSource = {
  wrapper: { className: 'home-page-wrapper pricing1-wrapper' },
  page: { className: 'home-page pricing1' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      { name: 'title', children: 'Pricing', className: 'pricing1-title-h1' },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: 'Baseline' },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <font>
                  <font>$ 123.99</font>
                </font>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
                Baseline Watermark<br /><br />Bring your own content<br /> OR<br />Includes one free photoshoot.<br /><br />Subject to supplies & schedule.
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: 'mailto: contact@unlimitedlightworks.com?subject=Interested in a baseline watermark quote',
                children: 'Email for quote',
              },
            },
          },
        },
      },
      {
        name: 'block1',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box active' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: 'Low' },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <font>
                  <font>$336.99+</font>
                </font>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
           Low Watermark<br />Pick the color of the watermark. <br /><br /> Bring your own content<br /> OR<br /> Includes one free photoshoot. <br /><br />Subject to supplies & schedule.
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: 'mailto: contact@unlimitedlightworks.com?subject=Interested in a level low watermark quote',
                children: 'Email for quote',
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: 'Elevated' },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <font>
                  <font>$3336.99+</font>
                </font>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content',
            children: (
              <span>
              Elevated Watermark<br /> Contact to discuss needs.
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button',
                href: 'mailto: contact@unlimitedlightworks.com?subject=Interested in a high watermark quote',
                children: 'Email for quote',
              },
            },
          },
        },
      },
    ],
  },
};
// export const Content3colDataSource = {
//   wrapper: { className: 'home-page-wrapper content0-wrapper' },
//   page: { className: 'home-page content0' },
//   OverPack: { playScale: 0.3, className: '' },
//   titleWrapper: {
//     className: 'title-wrapper',
//     children: [{ name: 'title', children: 'Products and Services' }],
//   },
//   childWrapper: {
//     className: 'content0-block-wrapper',
//     children: [
//       {
//         name: 'block0',
//         className: 'content0-block',
//         md: 8,
//         xs: 24,
//         children: {
//           className: 'content0-block-item',
//           children: [
//             {
//               name: 'image',
//               className: 'content0-block-icon',
//               children:
//                 '/imgs/ant/receipt.png',
//             },
//             {
//               name: 'title',
//               className: 'content0-block-title',
//               children: (
//                 <span>
//                   <font>
//                     <font>One-stop business access</font>
//                   </font>
//                 </span>
//               ),
//             },
//             {
//               name: 'content',
//               children: (
//                 <span>
//                   <font>
//                     <font>
//                       The efficiency of payment, settlement, and accounting
//                       access products quadrupled
//                     </font>
//                   </font>
//                 </span>
//               ),
//             },
//           ],
//         },
//       },
//       {
//         name: 'block1',
//         className: 'content0-block',
//         md: 8,
//         xs: 24,
//         children: {
//           className: 'content0-block-item',
//           children: [
//             {
//               name: 'image',
//               className: 'content0-block-icon',
//               children:
//                 '/imgs/ant/presentation.png',
//             },
//             {
//               name: 'title',
//               className: 'content0-block-title',
//               children: 'One-stop in-process risk monitoring',
//             },
//             {
//               name: 'content',
//               children: 'Pre-risk control and quality control capabilities in all requirements configuration links',
//             },
//           ],
//         },
//       },
//       {
//         name: 'block2',
//         className: 'content0-block',
//         md: 8,
//         xs: 24,
//         children: {
//           className: 'content0-block-item',
//           children: [
//             {
//               name: 'image',
//               className: 'content0-block-icon',
//               children:
//                 '/imgs/ant/growth.png',
//             },
//             {
//               name: 'title',
//               className: 'content0-block-title',
//               children: 'One-stop data operation',
//             },
//             {
//               name: 'content',
//               children: 'Precipitate product access efficiency and operational efficiency data',
//             },
//           ],
//         },
//       },
//     ],
//   },
// };
export const Teams1xDataSource = {
  wrapper: { className: 'home-page-wrapper teams0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page teams0' },
  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          children: [
            {
              name: 'image',
              children:
                '/imgs/ceo-cat-small.jpg',
              className: 'teams0-image',
            },
            {
              name: 'content',
              children:
                '"I fully stand behind the quality of our watermark offering." was a well known quote by the ex CEO Simba. As a former CEO who has left the company due to layoffs, it\'s likely that this individual has a significant history of leadership and business experience. They would have been responsible for setting the strategic direction of the company, overseeing its day-to-day operations, and ensuring its financial success. Depending on the circumstances of their departure, they may have left on good terms, indicating that they were a respected and valued member of the company. In this case, they may have been praised for their leadership skills, their ability to build and manage a strong team, and their success in growing and expanding the company. However, it\'s also possible that their departure may have been less positive, such as due to conflicts with the board of directors or a decline in the company\'s performance. In this case, their bio may reflect their achievements during their tenure as CEO, while also acknowledging the challenges and difficulties that led to their departure.',
              className: 'teams0-content',
            },
            {
              name: 'title',
              children: (
                <span>
                  <font>
                    <font>Simba</font>
                  </font>
                </span>
              ),
              className: 'teams0-h1',
            },
            {
              name: 'content2',
              children: 'ex CEO',
              className: 'teams0-content',
            },
          ],
        },
      },

    ],
  },
};
// export const DetailsLeftDataSource = {
//   wrapper: { className: 'home-page-wrapper content2-wrapper' },
//   OverPack: { className: 'home-page content2', playScale: 0.3 },
//   imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
//   img: {
//     children: '/imgs/park.png',
//   },
//   textWrapper: { className: 'content2-text', md: 14, xs: 24 },
//   title: {
//     className: 'content2-title',
//     children: (
//       <span>
//         <font>
//           <font>Distributed middleware</font>
//         </font>
//       </span>
//     ),
//   },
//   content: {
//     className: 'content2-content',
//     children: (
//       <span>
//         <p>
//           <font>
//             <font>
//               Financial-level online transaction processing middleware,
//               large-scale distributed computers, tens of thousands of
//               transactions per second level concurrency capability, and strictly
//               guarantee the uniformity of transaction data. Financial-level
//               online transaction processing middleware, large-scale distributed
//               computers, tens of thousands of transactions per second level
//               concurrency capability, and strictly guarantee the uniformity of
//               transaction data.
//             </font>
//           </font>
//         </p>
//       </span>
//     ),
//   },
// };
// export const Footer3ColDataSource = {
//   wrapper: { className: 'home-page-wrapper footer1-wrapper' },
//   OverPack: { className: 'footer1', playScale: 0.2 },
//   block: {
//     className: 'home-page',
//     gutter: 0,
//     children: [
//       {
//         name: 'block0',
//         xs: 24,
//         md: 6,
//         className: 'block',
//         title: {
//           className: 'logo',
//           children:
//             '/imgs/logo-purple.svg',
//         },
//         childWrapper: {
//           className: 'slogan',
//           children: [
//             {
//               name: 'content0',
//               children: 'Animation specification and components of unlimited light works Design.',
//             },
//             {
//               name: 'content1',
//               children:'/imgs/small-ant-logo.svg',
//             }
//
//
//           ],
//         },
//       },
//       {
//         name: 'block1',
//         xs: 24,
//         md: 6,
//         className: 'block',
//         title: { children: 'product' },
//         childWrapper: {
//           children: [
//             { name: 'link0', href: '#', children: 'Product update record' },
//             { name: 'link1', href: '#', children: 'API Documentation' },
//             { name: 'link2', href: '#', children: 'Quick Start' },
//             { name: 'link3', href: '#', children: 'Reference guide' },
//
//             {name: 'link4', href:"#", children: '/imgs/small-ant-logo.svg'}
//           ],
//         },
//       },
//       {
//         name: 'block2',
//         xs: 24,
//         md: 6,
//         className: 'block',
//         title: { children: 'About' },
//         childWrapper: {
//           children: [
//             { href: '#', name: 'link0', children: 'FAQ' },
//             { href: '#', name: 'link1', children: 'Contact Us' },
//           ],
//         },
//       },
//       {
//         name: 'block3',
//         xs: 24,
//         md: 6,
//         className: 'block',
//         title: { children: 'Resource' },
//         childWrapper: {
//           children: [
//             { href: '#', name: 'link0', children: 'unlimited light works Design' },
//             { href: '#', name: 'link1', children: 'unlimited light works Motion' },
//           ],
//         },
//       },
//     ],
//   },
//   copyrightWrapper: { className: 'copyright-wrapper' },
//   copyrightPage: { className: 'home-page' },
//   copyright: {
//     className: 'copyright',
//     children: (
//       <span>
//         ©2022 by <a href="https://unlimited light works.com">unlimited light works</a> All Rights
//         Reserved
//       </span>
//     ),
//   },
// };

export const MetricsDataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: { className: 'feature6-title-text', children: 'Service Metrics' },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'million' },
              toText: true,
              children: '116',
            },
            children: { className: 'feature6-text', children: 'Model Data' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'million' },
              toText: true,
              children: '1.17',
            },
            children: { className: 'feature6-text', children: 'The number of model iterations' },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'million' },
              toText: true,
              children: '2.10',
            },
            children: { className: 'feature6-text', children: 'number of training samples' },
          },
        ],
      },
      {
        title: { className: 'feature6-title-text', children: 'Service Metrics' },
        className: 'feature6-item',
        name: 'block1',
        children: [
          {
            md: 8,
            xs: 24,
            name: 'child0',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'million' },
              toText: true,
              children: '116',
            },
            children: { className: 'feature6-text', children: 'model data' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child1',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'million' },
              toText: true,
              children: '1.17',
            },
            children: { className: 'feature6-text', children: 'The number of model iterations' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child2',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'million' },
              toText: true,
              children: '2.10',
            },
            children: { className: 'feature6-text', children: 'number of training samples' },
          },
        ],
      },
    ],
  },
};

export const PricingChartDataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      { name: 'title', children: 'price list', className: 'pricing2-title-h1' },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'name',
          key: 'name',
          name: 'empty',
          childWrapper: {
            children: [
              { name: 'name', children: ' ' },
              { name: 'content', children: ' ' },
            ],
          },
        },
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: 'Free',
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: '$0',
              },
              { name: 'button', children: { href: '#', children: 'Free Trail' } },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: 'Basic',
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: '$550',
              },
              { name: 'button', children: { href: '#', children: 'Buy Now' } },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: 'Pro',
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: '$2,200',
              },
              {
                name: 'button',
                children: { href: '#', type: 'primary', children: 'Buy Now' },
              },
            ],
          },
        },
        {
          dataIndex: 'unlimited',
          key: 'unlimited',
          name: 'unlimited',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: 'Unlimited',
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: '$5,600',
              },
              { name: 'button', children: { href: '#', children: 'Buy Now' } },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: 'Example Series 1',
            },
            {
              children: 'Limited',
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: 'Unlimited',
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: 'Unlimited',
              name: 'content2',
              className: 'pricing2-table-content',
            },
            {
              children: 'Unlimited',
              name: 'content3',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: 'Example Series 2',
            },
            {
              children: 'Limited',
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: 'Unlimited',
              name: 'content1',
              className: 'pricing2-table-content',
            },
            {
              children: 'Unlimited',
              name: 'content2',
              className: 'pricing2-table-content',
            },
            {
              children: 'Unlimited',
              name: 'content3',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: 'Fixed broadband billing',
            },
            {
              name: 'content0',
              children: '50GB',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: '250GB',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: '600GB',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children: 'Unlimited',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: 'Idle load balancing',
            },
            {
              children: '-',
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children:
                '/imgs/ant/chat.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                '/imgs/ant/chat.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                '/imgs/ant/chat.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list4',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: '4 Nuclear',
            },
            {
              name: 'content0',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children:
                '/imgs/ant/chat.svg',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                '/imgs/ant/chat.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list5',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: 'System disk (linear billing)',
            },
            {
              name: 'content0',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content2',
              children: '-',
              className: 'pricing2-table-content',
            },
            {
              name: 'content3',
              children:
                '/imgs/ant/chat.svg',
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const FlowsDataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      { name: 'title', className: 'feature8-title-h1', children: 'manual' },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: 'Simple and clear process, quick response to demand',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: { href: '#', children: 'Try it now' },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [

        {
          name: 'block1',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: 'Platform self-training process',
            className: 'feature8-carousel-title-block',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  '/imgs/ant/arrow.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      '/imgs/ant/chat.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: 'communication of needs',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: 'Communicate business needs and connect with people. ',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  '/imgs/ant/arrow.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      '/imgs/ant/chat.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: 'communication of needs',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      'Communicate business needs and connect with people. Communicate business needs and connect with people. ',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  '/imgs/ant/arrow.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      '/imgs/ant/chat.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: 'communication of needs',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      'Communicate business needs and connect with people. Communicate business needs and connect with people. ',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  '/imgs/ant/arrow.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      '/imgs/ant/chat.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: 'communication of needs',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      'Communicate business needs and connect with people. Communicate business needs and connect with people. ',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};

export const FooterRowDataSource = {
  wrapper: { className: 'home-page-wrapper footer2-wrapper' },
  OverPack: { className: 'home-page footer2', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: [
      {
        name: 'copyright',

        children: 'Illustrations licensed, all other © Unlimited Light Works',
        className: 'copyright-text',
      },
    ],
  },
  links: {
    className: 'links',
    children: [
      /*{
        name: 'Illustration by Dani Grapevine',
        href: 'https://icons8.com/illustrations/author/JTmm71Rqvb2T',
        className: 'copyright-text',
        children:
          '/imgs/small-ant-logo.svg',
      },*/

    ],
  },
};
