/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

// import NavBarDark from './NavBarDark';
import NavBarLight from './NavBarLight';

import Banner from './Banner';
// import DetailsRight from './DetailsRight';
// import Video from './Video';
import Content6 from './Details6';
import Pricing3 from './Pricing3';
// import Content3 from './Content3Col';
import Teams0 from './Teams0';
// import DetailsLeft from './DetailsLeft';
// import Footer3Col from './Footer3Col';
import FooterRow from './FooterRow';
// import Metrics from './Metrics';
// import PricingChart from './PricingChart';
// import Flows from './Flows';

import {
  // NavBarDarkDataSource,
  NavBarLightDataSource,

  BannerDataSource,
  // DetailsRightDataSource,
  // VideoDataSource,
  Details6DataSource,
  Pricing3DataSource,
  // Content3colDataSource,
  Teams1xDataSource,
  // DetailsLeftDataSource,
  // Footer3ColDataSource,
  FooterRowDataSource,
  // MetricsDataSource,
  // PricingChartDataSource,
  // FlowsDataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      // <NavBarDark
      //   id="Nav0_1"
      //   key="Nav0_1"
      //   dataSource={NavBarDarkDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <NavBarLight
        id="Nav0_3"
        key="Nav0_3"
        dataSource={NavBarLightDataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner
        id="Banner5_0"
        key="Banner5_0"
        dataSource={BannerDataSource}
        isMobile={this.state.isMobile}
      />,
      // <DetailsRight
      //   id="Feature1_0"
      //   key="Feature1_0"
      //   dataSource={DetailsRightDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      // <Video
      //   id="Content4_0"
      //   key="Content4_0"
      //   dataSource={VideoDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Content6
        id="Content3_0"
        key="Content3_0"
        dataSource={Details6DataSource}
        isMobile={this.state.isMobile}
      />,
      <Pricing3
        id="Pricing1_0"
        key="Pricing1_0"
        dataSource={Pricing3DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Content3
      //   id="Content0_0"
      //   key="Content0_0"
      //   dataSource={Content3colDataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Teams0
        id="Teams0_0"
        key="Teams0_0"
        dataSource={Teams1xDataSource}
        isMobile={this.state.isMobile}
      />,
    //   <DetailsLeft
    //     id="Feature2_0"
    //     key="Feature2_0"
    //     dataSource={DetailsLeftDataSource}
    //     isMobile={this.state.isMobile}
    //   />,
    //   <Metrics
    //   id="Feature6_0"
    //   key="Feature6_0"
    //   dataSource={MetricsDataSource}
    //   isMobile={this.state.isMobile}
    // />,
    //
    // <PricingChart
    //   id="Pricing2_0"
    //   key="Pricing2_0"
    //   dataSource={PricingChartDataSource}
    //   isMobile={this.state.isMobile}
    // />,
    // <Flows
    //   id="Feature8_0"
    //   key="Feature8_0"
    //   dataSource={FlowsDataSource}
    //   isMobile={this.state.isMobile}
    // />,
    //   <Footer3Col
    //     id="Footer1_0"
    //     key="Footer1_0"
    //     dataSource={Footer3ColDataSource}
    //     isMobile={this.state.isMobile}
    //   />,
      <FooterRow
        id="Footer2_0"
        key="Footer2_0"
        dataSource={FooterRowDataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
